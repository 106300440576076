/* Your app custom styles here */

body,
div,
p,
a {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}

.device-ios,
.device-android {}

.device-desktop .block {
  width: 95%;
  margin: 10px auto;
}

.navbar-bg {
    background: #f2ba39;
    color:#444;
}

.page-content.home {
    background: #ffffff;
    color:#444;
}

.navbar .title-large-text {
    color:#444 !important;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 95%;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-container.slides {
    width: 95%;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-container.slides .swiper-slide {
    background-size: contain;
    background-position: center;
    border-radius: 0px;
    width: 220px;
    max-width: 220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 8px;
  }